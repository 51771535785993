import api from "!../../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/cjs.js??clonedRuleSet-10.use[1]!../../../node_modules/.pnpm/vue-loader@16.8.3_@vue+compiler-sfc@3.5.5_vue@3.5.5_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/.pnpm/postcss-loader@6.2.1_postcss@8.4.45_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-10.use[2]!../../../node_modules/.pnpm/vue-loader@16.8.3_@vue+compiler-sfc@3.5.5_vue@3.5.5_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./ExtraInformation.vue?vue&type=style&index=0&id=3d9a5ada&lang=css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};