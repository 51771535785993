<script setup>
import { reactive, ref, toRefs, h, onMounted, watchEffect, onBeforeUnmount } from "vue";
import { message, Modal } from 'ant-design-vue';
import { notification } from 'ant-design-vue';
import tourdata from './page/TourDadata.vue'
import ContactTravellers from './page/ContactTravellers.vue'
import Contactinformation from './page/ContactInfomation.vue'
import extra from './page/Extras.vue'
import hoteles from './page/Hotel.vue'
import extraInformation from './page/ExtraInformation.vue'
import axios from 'axios'

import Sum from "./page/summary.vue";
const key = 'updatable';

const props = defineProps({
    title: String,
    duration: String,
    current_date:String,
    idtour: {
        type: Number,
        default: 0
    },
    lang: String,
    catid: Number,
    hotels: Object,
    includeextra: Object,
    postlink: String,
    linktour: String,
    retals: {
        type: Object,
        default: null
    }
});
const { title, idtour, catid, postlink,current_date, duration, linktour, retals, hotels, includeextra, lang } = toRefs(props);
// hasRentals(idtour.value,catid.value)
const dataExtras = ref(includeextra.value)
// console.log(retals.value)
const getRentals = ref(retals.value) //Exlude= rentals

const hasValidate = ref(null)
const spinning = ref(false);
const pearurl = ref(false);


const HasExtraSam = reactive({
    hasHike: false,
    showPopup: false,
    noupgrate: true,
    text: 'Rentals and Add-ons'
});



onMounted(() => {
    hasRentals(idtour.value, catid.value)
  
    pearurl.value = PEARSCRIPTB;
    document.addEventListener('dateGlobalChanged', updateStartDate);
})


// Función hasRentals en JavaScript
const hasRentals = (id, catid) => {

    const isExclude = getRentals.value.exclude.includes(id);
    const isIncludeCat = getRentals.value.exclude.includes(catid);
    if (isExclude || isIncludeCat) {
        HasExtraSam.showPopup = false;
        clearFormData();
        return
    }
    const hasIdInExtras = dataExtras.value.includes(id);
    const hasCatIdInExtras = dataExtras.value.includes(catid);

    const isIdExcludedRentals = getRentals.value.excludeRentals.includes(id);
    const isCatIdExcludedRentals = getRentals.value.excludeRentals.includes(catid);
    const isIdInNoUpgrade = getRentals.value.noupgrate.includes(id);
    const isCatIdInNoUpgrade = getRentals.value.noupgrate.includes(catid);

    if (hasIdInExtras || hasCatIdInExtras) {
        HasExtraSam.showPopup = true;

        if (isIdExcludedRentals || isCatIdExcludedRentals) {
            HasExtraSam.text = 'Upgrade Extras';
            HasExtraSam.hasHike = false;
        } else {
            HasExtraSam.text = 'Rentals and Add-ons';
            HasExtraSam.hasHike = true;
        }

        if (isIdInNoUpgrade || isCatIdInNoUpgrade) {
            HasExtraSam.text = 'Upgrade Extras';
            HasExtraSam.noupgrate = false;
            clearFormData();
        } else {
            HasExtraSam.text = 'Rentals and Add-ons';
            HasExtraSam.noupgrate = true;
        }
        return;
    }
    HasExtraSam.showPopup = false;
    clearFormData();
};

const clearFormData = () => {
    const extraEmit = {
        selectedItems: [],
        upgrateTrain: { name: "Expedition Train (Included)", price: 0 },
        extraHike: null
    };
    for (const property in extraEmit) {
        if (formdata.hasOwnProperty(property)) {
            delete formdata[property];
        }
    }
};

// end
const formdata = reactive({
    // tour information
    keytour: idtour.value,
    duration: duration.value,
    tour: title.value,
    linktour: linktour.value,
    typeService: 'Group',
    startDate: current_date.value,
    alterDate: null,
    // conatc main
    name: null,
    email: null,
    phone: '',
    // list travellers
    travelers: [
        {
            fullname: '',
            gender: 'Male',
            docType: 'Passport',
            docNumber: '',
            birthdate: '',
            country: '',
            email: '',
            estudent: 'No',
            food: 'No',
            foodDes: '',

        }
    ],
    // adicional informatio
    adicionalInformation: '',
    typeRoom: '',
    typeRoomInfo: '',
    hotellocation: '',
    aboutSam: '',
    numberEmergency: '',
    Term: false,
    file: null

});

// 
// Observa cambios en window.dateGlobal y actualiza la referencia reactiva

const updateStartDate = () => {
    formdata.startDate = window.dateGlobal;
};
onBeforeUnmount(() => {
    document.removeEventListener('dateGlobalChanged', updateStartDate);
});
// submitForm - Logs a message indicating that the form fields are completed
function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
const submitForm = async () => {
    // Función para verificar si todos los campos requeridos están presentes
    const requiredFields = [
        'keytour', 'tour', 'typeService', 'startDate',
        'name', 'email', 'phone', 'adicionalInformation', 'aboutSam',
        'numberEmergency', 'Term'
    ];


    // Example usage:


    const travelersFields = ['fullname', 'docType', 'docNumber', 'birthdate', 'country', 'email'];
    for (const field of requiredFields) {
        if (!formdata[field]) {
            notification.error({
                class: 'border-0 text-white',
                message: `Please fill in the ${field} field`,
                placement: "bottom",
                duration: 5,
                style: {
                    backgroundColor: 'red',
                    text: '#fff'
                },
            });
            return;
        }
    }

    if (!formdata.travelers.every(traveler => travelersFields.every(field => traveler[field]))) {
        notification.error({
            class: 'border-0 text-white',
            message: `Please fill in all fields for each traveler`,
            placement: "bottom",
            duration: 5,
            style: {
                backgroundColor: 'red',
                text: '#fff'
            },
        });
        return;
    }
    if (!isValidEmail(formdata.email)) {
        notification.error({
            class: 'border-0 text-white',
            message: `Email field is invalidl`,
            placement: "bottom",
            duration: 5,
            style: {
                backgroundColor: 'red',
                text: '#fff'
            },
        });
        return;
    }
    // dd
    spinning.value = true;
    // const json = JSON.stringify(formdata);
    // const blob = new Blob([json], {
    // type: 'application/json'
    // })
    const DB = new FormData();
    DB.append('travellers', JSON.stringify(formdata));
    DB.append('pear_hook', pearurl.value.pear_hook)
    DB.append('action', pearurl.value.action_booking)
    if (formdata.file) {
        formdata.file.forEach(image => {
            DB.append('files[]', image);
        });
    }
    if (!formdata.file) {
        DB.append('files[]', null);
    };


    axios.post(pearurl.value.ajax_url, DB, {
                header: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            })
        .then(response => {
            spinning.value = false;
            const jsonData = response.data;
      
            //  console.log(response)
            if (jsonData.success) {
                spinning.value = false;
                Modal.success({
                    title: jsonData.data.title,
                    content: h('div', {}, [,
                        h('a', {
                            href: jsonData.data.linkPay,
                            class: 'btn btn-primary rounded-0 text-white',
                            rel: 'noopener noreferrer',

                        }, jsonData.data.message),
                    ]),
                    maskClosable: false, // Prevent closing when clicking outside
                    centered: true,
                    keyboard: false, // Prevent closing with the Escape key
                    footer: false,
                    // okText: null,
                    // onOk() {
                    //     console.log('ok');
                    // },
                });
            } else if(jsonData.data.status===500) {
                spinning.value = false;
                Modal.error({
                    title: jsonData.data.title,
                    maskClosable: false, // Prevent closing when clicking outside
                    centered: true,
                    keyboard: false, // Prevent closing with the Escape key
                    okText: 'Close',
                    okType: 'danger',
                    onOk() {

                    },
                });
            }else{
                Modal.error({
                title: jsonData.data,
                content: h('div', {}, [
                    h('button', {
                        class: 'btn btn-secondary rounded-0 text-white',
                        onclick: function() {
                            window.location.reload(); // Actualizar la página
                        }
                    }, 'Refresh page') // Botón para actualizar la página
                ]),
                maskClosable: false, // Evitar cerrar al hacer clic afuera
                okText: 'Close',
                centered: true,
                keyboard: false, // Evitar cerrar con la tecla Escape
                footer: false,
            });

            }
        })
        .catch(error => {
            Modal.error({
                    title: 'Something went wrong. Please try again without attaching your passport.',
                    maskClosable: false, // Prevent closing when clicking outside
                    centered: true,
                    keyboard: false, // Prevent closing with the Escape key
                    okText: 'Close',
                    okType: 'danger',
                    onOk() {

                    },
                });
            spinning.value = false;
        });

};

// getExtrapas - Adds extra data to the formdata object
const getExtrapas = async (extraEmit) => {
    Object.assign(formdata, extraEmit);

};
// get_file
const getFile = (e) => {
    formdata.file = e;
}

// getSelectTitle - Updates formdata based on the selected tour and changes has_extra_value value
const getSelectTitle = async (newtour) => {
    formdata.tour = newtour.title;
    formdata.linktour = newtour.link;
    formdata.duration = newtour.numday;
    let catid = newtour.catid;
    hasRentals(newtour.idIncluded, catid);
    // let cat = await dataExtras.value.includes(catid);
};

const getContactInformation = (e) => {
    formdata.name = e.name;
    formdata.email = e.email;
    // console.log(e)
}

// addTraveler - Adds a new traveler to formdata.travelers array
const addTraveler = (newTraveler) => {
    if (formdata.travelers.length < 10) {
        formdata.travelers.push(newTraveler);
    }
};

// deleteTraveler - Deletes a traveler from formdata.travelers array based on the provided index
const deleteTraveler = (index) => {
    // console.log(formdata.travelers.length)
    // Check if there is more than one traveler before deleting
    if (formdata.travelers.length > 1) {
        formdata.travelers.splice(index, 1); // Elimina 1 elemento en 
    }
};



</script>

<template>
    <div class="container-xxl">
        <div class="row">
            <div class="col-md-8">
                <form @submit.prevent="submitForm" class="gy3">
                    <tourdata :formdata="formdata" @selecttitle="getSelectTitle" :hasValidate="hasValidate" :lang="lang">
                    </tourdata>
                    <!-- conrtac data Travellers -->
                    <ContactTravellers :formdata="formdata" @addtraveleremit="addTraveler"
                        @deletetraveleremit="deleteTraveler" :lang="lang">
                    </ContactTravellers>
                    <!-- {{ HasExtraSam }} -->
                    <!-- Extra Routes -->
                    <extra v-if="HasExtraSam.showPopup" @extraEmit="getExtrapas" :hasTrek="HasExtraSam" :lang="lang">
                    </extra>

                    <Contactinformation :formdata="formdata" @setContactInformation="getContactInformation" :lang="lang">
                    </Contactinformation>
                    <hoteles :include="hotels"></hoteles>
                    <extraInformation :formdata="formdata" @setimage="getFile" :lang="lang"></extraInformation>
                    <div class="col-md-12 my-4 text-center">
                        <a-spin :spinning="spinning">
                            <button class="btn btn-dark px-5 btn-lg rounded-0 text-uppercase letter-spacing-1"
                                type="submit">
                                {{ lang === 'en' ? "Send form & Pay" : (lang === 'pt' ? "Enviar formulário e pagar" :
                                    "Enviar formulario y pagar") }}
                            </button>
                        </a-spin>
                    </div>
                </form>
            </div>
            <div class="col-md-4">
                <aside class="position-sticky " style="top:35px;">
                    <Sum title="Tu reservacion" :list="formdata" :lang="lang"> </Sum>
                </aside>
            </div>
        </div>
    </div>
</template>

<style>.ant-notification-notice-message {
    color: #fff !important;
}</style>
